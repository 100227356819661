import React from 'react';
import Header from '../components/Header';


const Home = () => {
  return (
    <>
        <Header />
    </>
  )
}

export default Home